import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { AiOutlineTwitter, AiFillInstagram } from "react-icons/ai"
import { HiMail } from "react-icons/hi"

function Footer() {
  return (
    <Container fluid className="footer">
      <Row>
        <Col md="4" className="footer-copyright">
          <h3>Developed by Js.</h3>
        </Col>
        <Col md="4" className="footer-copyright">
          <h3>Copyright © 2019 Js.</h3>
        </Col>
        <Col md="4" className="footer-body">
          <ul className="footer-icons">
            <li className="social-icons">
              <a
                href={process.env.REACT_APP_TWITTER}
                style={{ color: "white" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiOutlineTwitter />
              </a>
            </li>
            <li className="social-icons">
              <a
                href={process.env.REACT_APP_INSTAGRAM}
                style={{ color: "white" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillInstagram />
              </a>
            </li>
            <li className="social-icons">
              <a
                href={`mailto:${process.env.REACT_APP_EMAIL}`}
                style={{ color: "white" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <HiMail />
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  )
}

export default Footer
